<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'นโยบายคุกกี้';
			const content = `
				<p>
					ขอต้อนรับสู่เว็บไซต์ yellowtire บริษัท เยลโลไทร์ จำกัด (“บริษัทฯ ” “เรา” หรือ “ของเรา”) 
					บริษัท เยลโลไทร์ จำกัด เห็นถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคลของท่านผู้ใช้บริการของเรา นโยบายคุกกี้นี้ทำขึ้นเพื่ออธิบายเกี่ยวกับการเก็บ ใช้ โอน และเปิดเผยข้อมูลของท่าน ทั้งบนหน้าเว็บไซต์ และแอปพลิเคชันบนโทรศัพท์มือถือ หรืออุปกรณ์เคลื่อนที่อื่นๆ (“เว็บไซต์”) รวมไปถึงวิธีการที่ท่านสามารถตรวจสอบคุกกี้ที่เราใช้
				</p>
				<p>
					เราได้แจกแจงชนิดของคุกกี้ที่เราใช้และสาเหตุที่ใช้คุกกี้เหล่านี้กับบริการของเราเพื่อให้ท่านทำความเข้าใจในนโยบายฉบับนี้ ข้อมูลที่ท่านให้กับเราหรือพันธมิตรของเราผ่านคุกกี้จะช่วยให้เราสามารถเสนอสินค้าและบริการได้อย่างเหมาะสมกับท่าน เราขอขอบคุณที่ท่านให้ความไว้วางใจ โดยเราจะใช้ข้อมูลเหล่านี้อย่างระมัดระวังและมีเหตุผลเพื่อนำเสนอบริการและประสบการณ์ที่ดีที่สุดโดยเฉพาะสำหรับท่าน
				</p>

				<ol>
					<li>
						<strong>คุกกี้และวิธีการใช้คุกกี้</strong>
						<p>
							คุกกี้ คือ ข้อมูลขนาดเล็กที่ติดตั้งอยู่ในอุปกรณ์ของท่าน หรือข้อความที่ออกให้กับอุปกรณ์ของท่าน เมื่อท่านเยี่ยมชมเว็บไซต์ และใช้เพื่อจัดเก็บหรือติดตามข้อมูลเกี่ยวกับการใช้เว็บไซต์ของท่านและนำมาใช้ในการวิเคราะห์กระแสความนิยม
							(trend) การบริหารจัดการเว็บไซต์ ติดตามการเคลื่อนไหวการใช้เว็บไซต์ของผู้ใช้บริการ หรือเพื่อจดจำการตั้งค่าของผู้ใช้บริการ ทั้งนี้ คุกกี้บางประเภทนั้นจำเป็น เป็นอย่างมาก มิฉะนั้นหน้าเว็บไซต์อาจจะไม่สามารถใช้การได้อย่างเหมาะสม
							และคุกกี้ประเภทอื่นๆ นั้น อนุญาตให้เราปรับปรุงประสบการณ์การเบราวซ์ของท่าน ปรับแต่งเนื้อหาตามความต้องการของท่าน และทำให้ปฏิสัมพันธ์ของท่านกับเว็บไซต์สะดวกมากขึ้น เนื่องจากคุกกี้จะจดจำชื่อผู้ใช้ (ในวิธีการที่ปลอดภัย)
							รวมทั้งจดจำการตั้งค่าทางภาษาของท่าน
						</p>

						<p>
							เมื่อท่านเยี่ยมชมเว็บไซต์ของเรา เราจะเก็บรวบรวมข้อมูลบางประการโดยอัตโนมัติจากท่านผ่านการใช้คุกกี้&nbsp; นอกจากนี้
							บุคคลภายนอกอาจออกคุกกี้ผ่านเว็บไซต์ของเราเพื่อมอบโฆษณาที่เกี่ยวข้องกับความสนใจของท่านตามกิจกรรมการเข้าเว็บไซต์ของท่าน
							บุคคลภายนอกเหล่านี้อาจเก็บรวบรวมประวัติการเข้าเว็บไซต์ของท่านหรือข้อมูลอื่นเพื่อให้ทราบว่าท่านเข้าถึงเว็บไซต์อย่างไร และเพจที่ท่านเยี่ยมชมหลังจากที่ออกจากเว็บไซต์ของเรา
							ข้อมูลที่รวบรวมผ่านตัวกลางอัตโนมัติเหล่านี้อาจมีความเกี่ยวข้องกับข้อมูลส่วนบุคคลที่ท่านได้ให้ไว้ก่อนหน้านี้บนเว็บไซต์ของเรา
						</p>
					</li>
					<li>
						<strong>การทำงานของคุกกี้และสาเหตุที่เราใช้</strong>
						<p>คุกกี้มีทั้งคุกกี้ที่เราสร้างขึ้นเอง และคุกกี้จากบุคคลภายนอก โดยคุกกี้ทั้ง 2 ชนิดมีความแตกต่างกัน</p>

						<p>คุกกี้ของเรา คือ คุกกี้ที่เราสร้างขึ้นเอง เพื่อประโยชน์ตามที่ระบุในนโยบายฉบับนี้ โดยข้อมูลคุกกี้จะส่งกลับมาที่เว็บไซต์และเซิร์ฟเวอร์ของเรา&nbsp;</p>

						<ul>
							<li>
								คุกกี้ที่มีจำเป็นอย่างยิ่ง คือ คุกกี้ชนิดนี้มีเพื่อทำให้ท่านสามารถใช้บริการเว็บไซต์และบริการในนั้นได้ เช่น การเข้าถึงพื้นที่ที่มีการรักษาความปลอดภัยในเว็บไซต์
								เราใช้คุกกี้ชนิดนี้เพื่อให้เราสามารถให้บริการของเราได้อย่างปลอดภัย ถูกต้อง และสามารถรองรับให้บริการพื้นฐานได้
							</li>
							<li>คุกกี้เพื่อความปลอดภัย คือ เราใช้คุกกี้ชนิดนี้เพื่อให้การดำเนินการโต้ตอบระหว่างท่านกับบริการของเราเป็นไปอย่างรวดเร็วและปลอดภัย คุกกี้ชนิดนี้จะช่วยตรวจสอบและป้องกันความเสี่ยงต่อความปลอดภัยที่อาจเกิดขึ้น</li>
							<li>
								คุกกี้เพื่อการทำงาน คือ คุกกี้ชนิดนี้ทำให้เราสามารถจดจำท่านและความต้องการของท่าน (เช่น ตัวเลือกภาษา หรือภาคพื้นที่ท่านอาศัยอยู่) รวมทั้งเสนอประสบการณ์ที่เหมาะสมกับท่าน
								คุกกี้ชนิดนี้ยังใช้เพื่อจดจำการตั้งค่าของท่านเกี่ยวกับขนาดตัวอักษร แบบอักษร (font) และส่วนอื่นๆ ที่ท่านสามารถปรับแต่งได้บนหน้าเว็บไซต์
							</li>
							<li>คุกกี้ของบุคคลภายนอก คือ คุกกี้พันธมิตรของเราสร้างขึ้น โดยพันธมิตร จะเก็บข้อมูลคุกกี้เหล่านี้เพื่อใช้ให้บริการท่าน</li>
							<li>
								คุกกี้เพื่อการติดตาม คือ เราใช้คุกกี้ชนิดนี้เพื่อทำความเข้าใจท่านจากการใช้บริการเว็บไซต์ของเรา เช่น วิธีการที่ท่านเข้ามาในเว็บไซต์ สินค้าที่ท่านเข้าเยี่ยมชม ความลึกของเว็บไซต์ที่ท่านเข้าชม และระยะเวลาที่ท่านใช้บนเว็บไซต์
								เราใช้ข้อมูลเหล่านี้เพื่อพัฒนาประสิทธิภาพของเว็บไซต์ รวมทั้งปรับปรุงการจัดวางสินค้าและบริการและประสบการณ์การใช้บริการของทางบนเว็บไซต์
							</li>
							<li>
								คุกกี้เพื่อการโฆษณา คือ คุกกี้ชนิดนี้เพื่อกำหนดโฆษณาที่เหมาะสมกับท่านโดยพิจารณาจากพฤติกรรมของท่านบนเว็บไซต์ของเรา นอกจากนี้ คุกกี้ชนิดนี้ยังใช้จำกัดจำนวนครั้งของโฆษณาที่ท่านเห็น รวมทั้งช่วยประเมินประสิทธิภาพของโฆษณาของเรา
								เรา เปิดเผยข้อมูลเกี่ยวกับกิจกรรมการใช้บริการเว็บไซต์ของท่านในรูปแบบข้อมูลไม่ระบุตัวตน (anonymized) ให้กับพันธมิตรด้านการโฆษณาและการสร้างสรรค์ผลงานของเรา
							</li>
							<li>
								คุกกี้เพื่อการทำงานของบุคคลภายนอก คือ พันธมิตรบางรายช่วยให้เราสามารถพัฒนาประสบการณ์การให้บริการเว็บไซต์โดยที่เราไม่จำเป็นต้องพัฒนาด้วยตัวเอง ตัวอย่างเช่น พันธมิตรอาจช่วยให้บริการสนทนาโต้ตอบ เสนอคูปองหรือข้อความบนหน้าเว็บไซต์
								พันธมิตรเหล่านี้จำเป็นต้องติดตั้งระบบคุกกี้ในการให้บริการ
							</li>
						</ul>
					</li>
					<li>
						<strong>วิธีการที่ท่านสามารถควบคุมคุกกี้</strong>
						<p>
							อินเตอร์เน็ตเบราว์เซอร์ส่วนใหญ่ จะให้ท่านเป็นคนตัดสินใจว่าจะยอมรับคุกกี้หรือไม่ ท่านสามารถตั้งค่าเกี่ยวกับการยอมรับคุกกี้ หรือท่านสามารถดาวน์โหลดโปรแกรมเพิ่มเติม (extensions) เพื่อช่วยให้ท่านสามารถควบคุมคุกกี้เหล่านี้ได้
							อย่างไรก็ตาม การปฏิเสธ ลบ ขัดขวาง หรือ บล็อคการติดตามคุกกี้บางประเภท อาจส่งผลกระทบต่อการแสดงผลของหน้าเว็บ หรือการให้บริการทั้งหมดหรือบางส่วนได้ ประสบการณ์การใช้งานเว็บไซต์ของท่าน
							หรือความสามารถในการเข้าถึงเว็บไซต์ของท่านในการใช้งานลักษณะหรือพื้นที่ทั้งหมดหรือบางส่วนของเว็บไซต์อาจถูกจำกัด
						</p>

						<p>
							อินเตอร์เน็ตเบราว์เซอร์ส่วนใหญ่จะให้ท่านเป็นคนตัดสินใจว่าจะยอมรับคุกกี้หรือไม่ หากท่านปฏิเสธ ลบออก หรือบล็อคการติดตามโดยคุกกี้ อาจกระทบประสบการณ์ผู้ใช้ของท่าน และหากปราศจากคุกกี้
							ความสามารถของท่านในการใช้งานลักษณะหรือพื้นที่ทั้งหมดหรือบางส่วนของเว็บไซต์อาจถูกจำกัด
						</p>

						<p>
							ดังนั้น หากท่านต้องการลบคุกกี้หรือต้องการปรับแต่งความต้องการเกี่ยวกับคุกกี้ ท่านสามารถทำได้ผ่านเว็บเบราว์เซอร์หลักที่ท่านใช้ หรือศึกษาวิธีการจัดการคุกกี้เหล่านี้ได้บนอินเทอร์เน็ต สำหรับแอปพลิเคชันที่ไม่ได้ทำงานบนเว็บเบราว์เซอร์
							การควบคุมเทคโนโลยีที่ทำงานในลักษณะเดียวกันกับคุกกี้จึงมีความแตกต่างออกไป ขึ้นอยู่กับยี่ห้อและรุ่นโทรศัพท์ และซอฟแวร์ที่ท่านใช้ ซึ่งทั้ง 2 ระบบหลัก (ได้แก่ iOS และ Android)
							ต่างมีตัวเลือกให้ท่านสามารถปรับแต่งข้อมูลของท่านที่แอปพลิเคชันสามารถเข้าถึงได้ตามที่ท่านต้องการ โดยท่านสามารถปรับแต่งการตั้งค่าเหล่านั้นได้ด้วยตัวท่านเอง
						</p>
					</li>
					<li>
						<strong>รายละเอียดการติดต่อเรา</strong>
						<p>
							หากท่านมีคำถาม ข้อกังวล หรือข้อสงสัย เกี่ยวกับ การใช้คุกกี้ของเรา ภายใต้นโยบายความเป็นส่วนตัวฉบับนี้ โปรดติดต่อเราได้ที่
						</p>
					</li>
				</ol>


				<br />

				<i>บริษัท เยลโลไทร์ จำกัด</i>
				<br />
				<i>อาคารรสา ทู ชั้น 18</i>
				<br />
				<i>เลขที่ 1818 ถนนเพชรบุรี</i>
				<br />
				<i>แขวงมักกะสัน เขตราชเทวี</i>
				<br />
				<i>กรุงเทพมหานคร 10400 ประเทศไทย</i>
				<br />
				<i>โทรศัพท์ : +66 (0) 2026 7407</i>
				<br />
				<i>อีเมล : info@yellowtire.com</i>
				<br />
				<i>เว็บไซต์ : https://www.yellowtire.com</i>
				<br />
				<i>เฟซบุ๊ก www.facebook.com/yellowtire&nbsp;</i>

				<br />
				<br />
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
		const title = 'นโยบายการใช้คุกกี้ | YELLOWTiRE';
		const desc = 'นโยบายการใช้คุกกี้สำหรับ YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>